


















































import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';

@Component({
    components: {
        Modal,
    },
})
export default class Privacy extends Vue {
    @Prop({ default: false }) private open!: boolean;
}
