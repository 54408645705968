

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Modal extends Vue {
    @Prop({ default: false }) private open!: boolean;
    @Prop({ default: 'newsletter' }) private type!: string;

    private closeModal(type: string): void {
        window.EventBus.$emit('closeModal', type);
    }
}
