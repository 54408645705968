



















































import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({})
export default class Header extends Vue {
    @Prop() private logo!: string;
    @Prop() private user!: any;

    private openModal(type: string): void {
        this.$parent.$emit('openModal', type);
    }

    private logout(): void {
        this.$emit('logout');
    }
}
