










import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
import Header from '@/components/Header.vue';
import About from '@/components/About.vue';
import Imprint from '@/components/Imprint.vue';
import Privacy from '@/components/Privacy.vue';
import store from './store';
import router from './router';

@Component({
    components: {
        Header,
        About,
        Imprint,
        Privacy,
    },
})
export default class App extends Vue {
    private logo: string = 'netfonds-logo.png';
    private modalOpen: any = {
        imprint: false,
        privacy: false,
    };

    private get user(): any {
        return store.state.user;
    }

    private logoutUser(): void {
        window.localStorage.removeItem('bundk_user');
        store.commit('clearUser');

        router.push('/login');
    }

    private created(): void {
        this.$on('openModal', (type: string) => {
            this.modalOpen[type] = true;
        });

        window.EventBus.$on('closeModal', (type: string) => {
            this.modalOpen[type] = false;
        });

        const user = window.localStorage.getItem('bundk_user');
        if (user !== undefined && user) {
            const userData = JSON.parse(user);
            store.commit('setUser', userData);
            axios.post('https://api.bdrops.space/bundk/api/check', this.user).then(() => {
                return true;
            }, () => {
                this.logoutUser();
            });
        }

    }
}
