









































import { Component, Vue } from 'vue-property-decorator';
import store from '../store';
import Page from '../models/page';

@Component
export default class About extends Vue {
    private get page(): Page {
        return store.state.page;
    }
}
