import Vue from 'vue'
import Vuex from 'vuex'
import Offer from '@/models/offer'
import TopOffer from '@/models/topoffer'
import Inquiry from '@/models/inquiry'

Vue.use(Vuex)

const offers: Offer[] = []
const topOffers: TopOffer[] = []
const inquiry: Inquiry = {
    pageId: null,
    subject: undefined,
    email: null,
    name: null,
    phone: null,
    text: null,
    configuratorLink: null,
}

export default new Vuex.Store({
    state: {
        offers: {
            BMW: offers,
            MINI: offers,
            Electro: offers,
        },
        page: {
            id: 11,
            employees: 'Vertriebspartner',
            name: 'Netfonds',
            contacts: [
                {
                    name: 'Egor Sidorenko',
                    function: 'Verkaufsberater',
                    phone: '040 . 766091-2417',
                    mail: 'netfonds@bundk.de',
                    image: 'sidorenko.jpg',
                    address:
                        '<p>B&amp;K GmbH · Buxtehuder Str. 112 · 21073 Hamburg</p>',
                    salutation: 'Herr',
                },
                {
                    name: 'Patric Nieke',
                    function: 'Vertrieb an Großkunden',
                    phone: '040 . 766091-6350',
                    mail: 'netfonds@bundk.de',
                    image: 'Patric_Nieke_2021.jpg',
                    address:
                        '<p>B&amp;K GmbH · Buxtehuder Str. 112 · 21073 Hamburg</p>',
                    salutation: 'Herr',
                },
            ],
            costs: {
                transport: 831.93,
                pickup: 495,
            },
            gaProperty: 'UA-82146111-14',
        },
        topOffers,
        inquiry,
        user: {
            username: null,
            token: null,
            firstname: null,
            lastname: null,
        },
    },
    getters: {
        activeBMWOffer: (state) => {
            const activeOffers = state.offers.BMW.filter(
                (item) => item.showFirst,
            )
            return state.offers.BMW.indexOf(activeOffers[0])
        },
        activeMINIOffer: (state) => {
            const activeOffers = state.offers.MINI.filter(
                (item) => item.showFirst,
            )
            return state.offers.MINI.indexOf(activeOffers[0])
        },
        activeElectroOffer: (state) => {
            const activeOffers = state.offers.Electro.filter(
                (item) => item.showFirst,
            )
            return state.offers.Electro.indexOf(activeOffers[0])
        },
    },
    mutations: {
        setOffers(state: any, offerArray: Offer[]) {
            const category = offerArray[0].category
            state.offers[category] = offerArray
        },
        setTopOffers(state: any, data: TopOffer[]) {
            state.topOffers = data
        },
        setInquiry(state: any, data: Inquiry) {
            Object.assign(state.inquiry, data)
        },
        setUser(state: any, user: any) {
            state.user = user
        },
        clearUser(state: any) {
            state.user = {
                username: null,
                token: null,
                firstname: null,
                lastname: null,
            }
        },
    },
    actions: {},
})
