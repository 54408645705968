import Vue from 'vue'
import store from './store'
import router from './router'
import App from './App.vue'
import vueSmoothScroll from 'vue-smooth-scroll'
import VueAnalytics from 'vue-analytics'

import '@/styles/_custom-bootstrap.scss'
import '@/styles/_globals.scss'

declare global {
    interface Window {
        EventBus: any;
    }
}

Vue.config.productionTip = false

Vue.use(vueSmoothScroll)
Vue.use(VueAnalytics, {
    id: store.state.page.gaProperty,
    autoTracking: {
        screenview: true,
    },
})

Vue.filter(
    'currency',
    (value: number): string => {
        if (value === undefined || value === null) {
            return ''
        }

        return value.toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'symbol',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })
    },
)

Vue.filter(
    'formalCurrency',
    (value: number): string => {
        if (value === undefined || value === null) {
            return ''
        }

        return value.toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'code',
        })
    },
)

Vue.filter(
    'localeNumber',
    (value: number): string => {
        if (!value) {
            return ''
        }

        return value.toLocaleString('de-DE', {
            style: 'decimal',
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        })
    },
)

Vue.filter(
    'percent',
    (value: number): string => {
        if (!value) {
            return ''
        }

        return value.toLocaleString('de-DE', {
            style: 'decimal',
            minimumFractionDigits: 2,
        })
    },
)

Vue.filter(
    'leftPad',
    (value: any, char: any = 0, count = 1): string => {
        if (!value) {
            return ''
        }

        let returnString = ''

        for (let i = 0; i < count; i++) {
            returnString += char
        }

        returnString += value

        return returnString
    },
)

Vue.filter('slug', (value: string) => {
    if (!value) {
        return ''
    }
    const LOWER = value.toLowerCase()
    const SLUG = LOWER.replace(/\s/gi, '-')

    return SLUG
})

window.EventBus = new Vue()

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app')
