




























































import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/components/Modal.vue'

@Component({
    components: {
        Modal,
    },
})

export default class Imprint extends Vue {
    @Prop({ default: false }) private open!: boolean;

    private get year(): number {
        const date = new Date()
        const year = date.getUTCFullYear()
        return year
    }
}
