import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const isAuthenticated = (): boolean => {
    return !!window.localStorage.getItem('bundk_user');
};

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: () =>
                import(/* webpackChunkName: "home" */ './views/Home.vue'),
            beforeEnter: (to: any, from: any, next: any): void => {
                if (!isAuthenticated()) {
                    next('/login');
                } else {
                    next();
                }
            },
        },
        {
            path: '/registrieren',
            name: 'register',
            component: () =>
                import(/* webpackChunkName: "register" */ './views/Registration.vue'),
        },
        {
            path: '/login',
            name: 'login',
            component: () =>
                import(/* webpackChunkName: "login" */ './views/Login.vue'),
        },
        {
            path: '/passwort-vergessen',
            name: 'forgotten',
            component: () =>
                import(/* webpackChunkName: "forgotten" */ './views/Forgotten.vue'),
        },
    ],
});

export default router;
